import { IRules } from ".";

export const ACTION = {
  NEU: "anmeldung:neu",
  UEBERNAHME: "anmeldung:uebernahme",
  UNTERBRECHUNG: "unterbrechung",
  ABMELDUNG: "abmeldung",
  WECHSEL: "wechsel",
};

export const rules: IRules = {
  // Aktivkunde (alle Prozesse)
  971080000: { static: [ACTION.NEU, ACTION.UEBERNAHME, ACTION.UNTERBRECHUNG, ACTION.ABMELDUNG, ACTION.WECHSEL] },
  // Aktivkunde ohne IBN
  971080001: { static: [ACTION.UEBERNAHME, ACTION.UNTERBRECHUNG, ACTION.ABMELDUNG, ACTION.WECHSEL] },
  // Gekündigt (keine Anmeldungen)
  971080002: { static: [ACTION.UNTERBRECHUNG, ACTION.ABMELDUNG, ACTION.WECHSEL] },
  // Inaktiv (keine Prozesse)
  971080003: { static: [] },
};
