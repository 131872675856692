import React, { useContext } from "react";
import { Row } from "react-bootstrap";

import { CanPerform } from "services/authentication/Can";
import { rules, ACTION } from "services/authentication/Rules";

import Tile from "./Tile";
import AnmeldungIcon from "./Tile/anmeldung.svg";
import UnterbrechungIcon from "./Tile/Baumassnahme.svg";
import AbmeldungIcon from "./Tile/Abmeldung.svg";
import WechselIcon from "./Tile/Geraetewechsel.svg";
import { ApiContext } from "services/ApiContext";

const Prozesseinstieg: React.FC = () => {
  const { getKunde } = useContext(ApiContext);

  const myRole = getKunde()?.bus_rolle?.toString() ?? "";

  return (
    <Row className="mt-0">
      <Tile
        helpText="Beantragen Sie neue Messstellen oder beauftragen Sie die Gelsenwasser, vorhandene Messstellen zu übernehmen"
        title="Mess&shy;stellen&shy;betrieb ANMELDEN"
        toPath="/anmeldung"
        iconPath={AnmeldungIcon}
        disabled={!(CanPerform(rules, myRole, ACTION.NEU) || CanPerform(rules, myRole, ACTION.UEBERNAHME))}
      />
      <Tile
        helpText="Melden Sie eine Baumaßnahme o.ä., wegen der Messstellen temporär außer Betrieb sein werden"
        title="Strom&shy;unter&shy;brechung MELDEN"
        toPath="/stromunterbrechung"
        iconPath={UnterbrechungIcon}
        disabled={!CanPerform(rules, myRole, ACTION.UNTERBRECHUNG)}
      />
      <Tile
        helpText="Nehmen Sie Messstellen dauerhaft außer Betrieb oder melden Sie den Messstellenbetrieb bei der Gelsenwasser ab"
        title="Mess&shy;stellen&shy;betrieb ABMELDEN"
        toPath="/abmeldung"
        iconPath={AbmeldungIcon}
        disabled={!CanPerform(rules, myRole, ACTION.ABMELDUNG)}
      />
      <Tile
        helpText="Fordern Sie neue Geräte an, um die Messstelle umzubauen"
        title="Geräte&shy;wechsel ANFORDERN"
        toPath="/geraetewechsel"
        iconPath={WechselIcon}
        disabled={!CanPerform(rules, myRole, ACTION.WECHSEL)}
      />
    </Row>
  );
};

export default Prozesseinstieg;
