import * as React from "react";
import { UserManagerSettings } from "oidc-client";
import AuthService, { IAuthService } from "./AuthService";

export interface AuthProviderProps {
  config: UserManagerSettings;
}

export const AuthContext = React.createContext<IAuthService>({
  signinRedirectCallback: () => ({}),
  logout: () => ({}),
  signoutRedirectCallback: () => ({}),
  isAuthenticated: () => false,
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  createSigninRequest: () => ({}),
  getUser: () => new Promise((_) => null),
});

export const AuthConsumer: React.Consumer<IAuthService> = AuthContext.Consumer;

export class AuthProvider extends React.Component<AuthProviderProps> {
  authService: AuthService;

  constructor(props: AuthProviderProps) {
    super(props);
    this.authService = new AuthService(props.config);
  }

  render(): JSX.Element {
    /* eslint-disable react/prop-types */
    return <AuthContext.Provider value={this.authService}>{this.props.children}</AuthContext.Provider>;
  }
}
