import React from "react";
import { Button, Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import classNames from "classnames/bind";

import styles from "./Tile.module.scss";

export interface TileProps {
  title: string;
  toPath: string;
  iconPath: string;
  onclick?: React.MouseEventHandler<HTMLElement> | undefined;
  helpText: string | JSX.Element;
  disabled?: boolean;
}

const cx = classNames.bind(styles);

const Tile: React.VFC<TileProps> = ({ title, toPath, iconPath, onclick, helpText, disabled = false }: TileProps) => {
  if (disabled) {
    helpText = "Diese Funktion ist für diese Firma nicht freigeschaltet. Bitte wenden Sie sich an die Gelsenwasser.";
    toPath = "#";
  }

  const buttonClassName = cx(
    {
      tile_action: true,
      disabled,
    },
    "t-1"
  );

  const bodyClassName = cx("d-flex", "flex-column", "justify-content-between", {
    cardbody__help: !!helpText,
    cardbody__disabled: disabled,
  });
  return (
    <Col md={6} lg={4} xl={3} className="mt-3">
      <Card className="text-center border-light h-100">
        <Card.Body className={bodyClassName}>
          <Card.Title className={styles.cardbody__title}>{title}</Card.Title>
          <Card.Text as="div">
            <img alt={`Icon für ${title}`} src={iconPath} className="mt-4 mb-4" width={60} height={60} />
            {helpText && <figcaption className={styles.help}>{helpText}</figcaption>}
            <Button variant="outline-primary" className={buttonClassName} as={Link} to={toPath} onClick={onclick}>
              Starten
            </Button>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default Tile;
